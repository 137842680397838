:root {
  --color-primary: rgb(255, 102, 0);
  --color-primary-20: rgba(255, 102, 0, 0.8);
  --color-black: #212529;
  --color-black-20: rgba(0, 0, 0, 0.8);
  --color-white: rgb(245, 245, 245);
  --color-red: #dc3545;
  --color-slate: rgb(50, 60, 65);
  --color-slate-10: rgba(50, 60, 65, 0.9);
  --color-slate-20: rgba(50, 60, 65, 0.8);
  --color-slate-40: rgb(173, 177, 179);
  --color-slate-70: rgba(50, 60, 65, 0.3);
  --color-suede: #e4e1dd;
  --color-suede-light: rgb(242, 240, 238);
  --color-clay: rgb(122, 104, 85);
  --color-clay-10: rgb(242, 240, 238);
  --color-clay-20: rgb(228, 225, 221);
  --color-clay-40: color(var(--color-clay) blend(white 60%));
  --color-grey6: rgb(171, 171, 171);
  --color-grey12: rgb(228, 225, 221);
  --color-slate-60Percent: rgb(111, 118, 122);
  --color-graphics-warningLight: rgb(254, 245, 228);
  --color-graphics-negativeLight: rgb(251, 230, 229);
  --color-graphics-positiveLight: rgb(241, 248, 229);
  --color-negative: rgb(208, 2, 27);
  --color-jade: rgb(0, 163, 150);
  --color-athens-gray: rgb(234, 235, 236);

  --color-icons-primaryDark: var(--color-slate);
  --color-icons-darkGrey: rgb(86, 86, 86);
  --color-icons-midGrey: var(--color-grey3);
  --color-icons-grey: var(--color-grey5);
  --color-icons-lightGrey: var(--color-grey4);
  --color-icons-white: var(--color-white);
  --color-icons-primaryBrand: var(--color-primaryBrand);
  --color-icons-secondaryBrand: var(--color-secondaryBrand);
  --color-icons-negative: var(--color-negative);
  --color-icons-positive: var(--color-positive);

  --color-primary-bquk: rgb(255, 102, 0);
  --color-primary-bqie: rgb(255, 102, 0);
  --color-primary-bdro: rgb(226, 0, 26);
}
