@import '@hip/theme/src/theme.module.css';
@import '@hip/theme/src/css/media.css';
@import '@hip/theme/src/css/colors.css';
@import '@hip/theme/src/css/sizing.css';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 4rem;
  min-width: 4rem;
  padding: var(--page-pad-half) var(--page-pad-double);
  cursor: pointer;
  border: none;
}

.Primary {
  background-color: var(--color-primary);
}

.Primary:hover {
  background-color: var(--color-primary-20);
}

.Secondary {
  background-color: var(--color-slate-20);
}

.Secondary:hover {
  background-color: var(--color-slate-70);
}

.Tertiary {
  border: var(--border-size-medium) solid var(--color-slate);
}

.Naked {
  border: none;
  padding: 0;
  background-color: transparent;
}

.Disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
